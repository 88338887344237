import * as myData from '../json/data.json';
import * as _ from 'lodash-es';
/**
 * class qui permet de créer des éléments HTML (ou Node)
 */
class Dynamically_create_element {
    constructor(data) {
        this.data = data;
    }
    create() {
        const element = document.createElement(this.data.tagName);
        if (this.data.attributes) {
            let key; // add this declaration
            for (key in this.data.attributes) {
                element.setAttribute(key, this.data.attributes[key]); // works
            }
        }
        if (this.data.onclick) {
            element.onclick = this.data.onclick;
        }
        if (this.data.classList) {
            element.classList.add(...this.data.classList);
        }
        element.innerHTML = this.data.innerHTML || '';
        return element;
    }
}
let USER_DATA = {
    firstname: '',
    lastname: '',
    mail: '',
    agreeToNotReceiveCommunication: false,
};
let QUIZ = [];
let STEP = 0;
const ANSWER_REGISTERED = [];
let PARAMS_FROM_URL = {};
window.onload = () => initialize();
function initialize() {
    PARAMS_FROM_URL = getAllUrlParams();
    initializeUserForm();
}
/**
 * initialise le formulaire pour la récuperations des  données de l'utilisateur.
 */
function initializeUserForm() {
    const forms = document.querySelectorAll('.needs-validation');
    const mailInput = document.querySelector('#mail');
    const agreeToNotReceiveCommunicationElement = (document.getElementById('agreeToNotReceiveCommunication'));
    // le champ "mail" nécessite d'être écouté pour effacer une classe css que l'on ajoute lorsque
    // notre verification de mail detecte un problème que bootstrap 5 laisse passer
    mailInput === null || mailInput === void 0 ? void 0 : mailInput.addEventListener('change', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.target;
        if ((target === null || target === void 0 ? void 0 : target.value) && mailValidator(target.value)) {
            target === null || target === void 0 ? void 0 : target.classList.remove('is-invalid');
        }
    });
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach((form) => form.addEventListener('submit', function (event) {
        form.classList.add('was-validated');
        event.preventDefault();
        event.stopPropagation();
        if (!mailValidator(form.mail.value)) {
            form.mail.classList.remove('is-valid');
            form.mail.classList.add('is-invalid');
        }
        else {
            if (form.checkValidity()) {
                // stockage des données de l'utilisateur récupéré via le formulaire
                USER_DATA = {
                    firstname: form.firstname.value,
                    lastname: form.lastname.value,
                    mail: form.mail.value,
                    agreeToNotReceiveCommunication: agreeToNotReceiveCommunicationElement === null || agreeToNotReceiveCommunicationElement === void 0 ? void 0 : agreeToNotReceiveCommunicationElement.checked,
                };
                createEvent(Object.assign(Object.assign({}, _.cloneDeep(USER_DATA)), _.cloneDeep(PARAMS_FROM_URL)));
                // initialisation du quiz
                initializeQuiz();
            }
        }
    }, false));
}
/**
 * verificatiçon du bon format du mail : [text]@[text].[texte]
 * @param mail
 */
function mailValidator(mail = '') {
    const REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return REGEX.test(mail);
}
/**
 * initialise le quiz en récupérant les données pour le questionnaire
 */
function initializeQuiz() {
    QUIZ = [];
    myData['data'].forEach((quiz, index) => {
        var _a, _b, _c, _d, _e, _f;
        if (quiz.subquestion) {
            let data;
            if ((_b = (_a = ANSWER_REGISTERED[index]) === null || _a === void 0 ? void 0 : _a.subquestionAnswersSelected) === null || _b === void 0 ? void 0 : _b.value) {
                data = quiz.subquestion.questions.find((question) => { var _a, _b; return question.subquestionAssociated === ((_b = (_a = ANSWER_REGISTERED[index]) === null || _a === void 0 ? void 0 : _a.subquestionAnswersSelected) === null || _b === void 0 ? void 0 : _b.value); });
            }
            else {
                data = quiz.subquestion;
            }
            QUIZ.push({
                isSubquestion: !((_d = (_c = ANSWER_REGISTERED[index]) === null || _c === void 0 ? void 0 : _c.subquestionAnswersSelected) === null || _d === void 0 ? void 0 : _d.value),
                display: false,
                feedback: (data === null || data === void 0 ? void 0 : data.feedback) || {
                    correct: '',
                    wrong: '',
                    partial: '',
                },
                question: (data === null || data === void 0 ? void 0 : data.question) || '',
                answers: (data &&
                    setAnswers(data, quiz.subquestion && !((_f = (_e = ANSWER_REGISTERED[index]) === null || _e === void 0 ? void 0 : _e.subquestionAnswersSelected) === null || _f === void 0 ? void 0 : _f.value))) ||
                    [],
                multiple: !!(data === null || data === void 0 ? void 0 : data.multiple) ||
                    ((data === null || data === void 0 ? void 0 : data.answers) || []).filter((answer) => { var _a; return ((_a = answer === null || answer === void 0 ? void 0 : answer.attributes) === null || _a === void 0 ? void 0 : _a.correct) === 'true'; }).length > 1,
                validated: false,
            });
        }
        else {
            QUIZ.push({
                display: false,
                feedback: quiz.feedback || {
                    correct: '',
                    wrong: '',
                    partial: '',
                },
                question: quiz.question || '',
                answers: setAnswers(quiz),
                multiple: !!quiz.multiple ||
                    (quiz.answers || []).filter((answer) => { var _a; return ((_a = answer === null || answer === void 0 ? void 0 : answer.attributes) === null || _a === void 0 ? void 0 : _a.correct) === 'true'; }).length > 1,
                validated: !!ANSWER_REGISTERED[index],
            });
        }
    });
    const stepToHideElement = document.querySelector('#beacon-0');
    const sectionUserForm = document.getElementById('section-user-form');
    if (stepToHideElement) {
        stepToHideElement.innerHTML = '';
    }
    if (sectionUserForm) {
        sectionUserForm.innerHTML = '';
    }
    createSpaceBetweenElement();
    createAndRenderQuiz();
}
/**
 * passage au questionnaire suivant
 * @param event
 */
function nextQuestion(event) {
    var _a, _b;
    event.preventDefault();
    event.stopPropagation();
    STEP++;
    removeClassesFromElement('body', ['success']);
    createAndRenderQuiz();
    if (!!((_a = QUIZ[STEP]) === null || _a === void 0 ? void 0 : _a.validated)) {
        if (!!((_b = QUIZ[STEP]) === null || _b === void 0 ? void 0 : _b.multiple)) {
            testAnswer(event, true);
        }
        else {
            correction(event, true);
        }
    }
}
/**
 * passage au questionnaire suivant
 * @param event
 */
function previousQuestion(event) {
    var _a, _b;
    event.preventDefault();
    event.stopPropagation();
    removeClassesFromElement('body', [`fond-${STEP + 1}`, 'success']);
    STEP--;
    createAndRenderQuiz();
    if (!!((_a = QUIZ[STEP]) === null || _a === void 0 ? void 0 : _a.validated)) {
        if (!!((_b = QUIZ[STEP]) === null || _b === void 0 ? void 0 : _b.multiple)) {
            testAnswer(event, true);
        }
        else {
            correction(event, true);
        }
    }
}
function createSpaceBetweenElement() {
    const beacon0 = document.querySelector('#beacon-0');
    if (beacon0) {
        beacon0.insertAdjacentHTML('afterbegin', '<div class="space-between"></div>');
    }
}
/**
 * création du quiz et injection du code html
 */
function createAndRenderQuiz() {
    const quiz = QUIZ[STEP];
    const questionNumberWrapper = document.querySelector('#question-number-info');
    if (questionNumberWrapper) {
        questionNumberWrapper.innerHTML = `Question ${STEP + 1} / ${QUIZ.length}`;
    }
    const quizContainer = document.querySelector('#quiz-container');
    if (quizContainer) {
        quizContainer.innerHTML = '';
    }
    const isTwoColumn = quiz.answers.length > 4;
    // inject question
    quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.insertAdjacentHTML('beforeend', quiz.question);
    // create form for quiz
    const formConf = {
        attributes: {
            noValidate: '',
        },
        tagName: 'form',
        classList: ['form-quiz', 'row'],
    };
    const form = new Dynamically_create_element(formConf).create();
    // create wrappers for one or two column inside form
    const answersWrapperConf = {
        tagName: 'div',
        classList: isTwoColumn ? ['answers-wrapper', 'col-12', 'col-md-6'] : ['answers-wrapper', 'col-12'],
    };
    const answersWrapper1 = new Dynamically_create_element(answersWrapperConf).create();
    const answersWrapper2 = new Dynamically_create_element(answersWrapperConf).create();
    // inject answers
    quiz.answers.forEach((answer, index) => {
        if (isTwoColumn) {
            if (index < 3) {
                answersWrapper1.appendChild(answer);
            }
            else {
                answersWrapper2.appendChild(answer);
            }
        }
        else {
            answersWrapper1.appendChild(answer);
        }
    });
    form.appendChild(answersWrapper1);
    if (isTwoColumn) {
        form.appendChild(answersWrapper2);
    }
    quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.appendChild(form);
    if (quiz.validated && !!QUIZ[STEP + 1] && !quiz.isSubquestion) {
        createBtnNext();
    }
    if (quiz.multiple) {
        if (!quiz.isSubquestion) {
            createBtnTestAnswer();
        }
    }
    if (!!QUIZ[STEP - 1] && !quiz.isSubquestion) {
        createBtnPrevious();
    }
    const classesToAdd = [`fond-${STEP + 1}`];
    const classesToRemoves = [`fond-${STEP}`];
    changeBodyBackground(classesToAdd, classesToRemoves);
}
/**
 * change le background selon les étapes du quiz
 * @param classesToAdd
 * @param classesToRemove
 */
function changeBodyBackground(classesToAdd, classesToRemove) {
    if (!!(classesToRemove === null || classesToRemove === void 0 ? void 0 : classesToRemove.length)) {
        removeClassesFromElement('body', classesToRemove);
    }
    addClassesFromElement('body', classesToAdd);
}
/**
 * utilitaire pour supprimer une ou plusieurs classes css d'un element html
 * @param selector
 * @param classes
 */
function removeClassesFromElement(selector, classes) {
    var _a, _b;
    const isSelectorAClass = selector.includes('.');
    const isSelectorAnId = selector.includes('#');
    const isSelectorATag = !selector.includes('.') && !selector.includes('#');
    if (isSelectorAClass) {
        const elements = document.querySelectorAll(selector);
        Array.prototype.slice.call(elements).forEach((element) => { var _a; return (_a = element.classList) === null || _a === void 0 ? void 0 : _a.remove(...classes); });
    }
    if (isSelectorAnId || isSelectorATag) {
        (_b = (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove(...classes);
    }
}
/**
 * utilitaire pour ajouter une ou plusieurs classes css à un element html
 * @param selector
 * @param classes
 */
function addClassesFromElement(selector, classes) {
    var _a, _b;
    const isSelectorAClass = selector.includes('.');
    const isSelectorATag = !selector.includes('.') && !selector.includes('#');
    const isSelectorAnId = selector.includes('#');
    if (isSelectorAClass) {
        const elements = document.querySelectorAll(selector);
        Array.prototype.slice.call(elements).forEach((element) => { var _a; return (_a = element === null || element === void 0 ? void 0 : element.classList) === null || _a === void 0 ? void 0 : _a.add(...classes); });
    }
    if (isSelectorAnId || isSelectorATag) {
        (_b = (_a = document.querySelector(selector)) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.add(...classes);
    }
}
/**
 * correction du quiz avec ajout des effets visuel via les classes css
 * @param event
 * @param reviewQuiz sert à savoir si l'utilisateur regarde un quiz deja corrigé
 */
function correction(event, reviewQuiz = false) {
    var _a, _b;
    event === null || event === void 0 ? void 0 : event.preventDefault();
    event === null || event === void 0 ? void 0 : event.stopPropagation();
    let currentElement = !reviewQuiz ? event === null || event === void 0 ? void 0 : event.currentTarget : null;
    if (!reviewQuiz && (!!((_a = QUIZ[STEP]) === null || _a === void 0 ? void 0 : _a.validated) || (currentElement === null || currentElement === void 0 ? void 0 : currentElement.getAttribute('validated')) === 'true')) {
        return;
    }
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if (reviewQuiz) {
        const answersRegistered = ANSWER_REGISTERED[STEP];
        currentElement = answers.find((answerElement) => { var _a; return answerElement.value === ((_a = answersRegistered === null || answersRegistered === void 0 ? void 0 : answersRegistered.answersSelected[0]) === null || _a === void 0 ? void 0 : _a.value); });
    }
    currentElement === null || currentElement === void 0 ? void 0 : currentElement.setAttribute('validated', 'true');
    const answerFeedback = (_b = (myData.data[STEP].answers || []).find((answer) => answer.attributes.value === (currentElement === null || currentElement === void 0 ? void 0 : currentElement.getAttribute('value')))) === null || _b === void 0 ? void 0 : _b.feedback;
    // bonne réponse
    if ((currentElement === null || currentElement === void 0 ? void 0 : currentElement.getAttribute('correct')) === 'true') {
        currentElement === null || currentElement === void 0 ? void 0 : currentElement.classList.add('was-correct');
        if (!answers.some((answerElement) => answerElement.getAttribute('correct') === 'true' &&
            answerElement.getAttribute('validated') !== 'true')) {
            changeBodyBackground(['success']);
            QUIZ[STEP].validated = true;
            if ((answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.correct) && (answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.correct) !== '') {
                currentElement === null || currentElement === void 0 ? void 0 : currentElement.insertAdjacentHTML('afterend', answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.correct);
            }
            else {
                currentElement === null || currentElement === void 0 ? void 0 : currentElement.insertAdjacentHTML('afterend', QUIZ[STEP].feedback.correct);
            }
            // sauvegarde des réponses de l'utilisateur
            if (!reviewQuiz) {
                saveAnswerSelected(answers);
            }
            // création d'un bouton suivant ou du bouton "voir mon resultat" en fonction de l'etape du quiz
            if (STEP === QUIZ.length - 1) {
                createBtnSeeMyResults();
            }
            else {
                createBtnNext();
            }
        }
    }
    else {
        // mauvaise réponse
        QUIZ[STEP].validated = true;
        currentElement === null || currentElement === void 0 ? void 0 : currentElement.classList.add('was-wrong');
        answers.forEach((answerElement) => {
            if (answerElement.getAttribute('correct') === 'true') {
                answerElement.classList.add('was-correct');
            }
        });
        if ((answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.wrong) && (answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.wrong) !== '') {
            currentElement === null || currentElement === void 0 ? void 0 : currentElement.insertAdjacentHTML('afterend', answerFeedback === null || answerFeedback === void 0 ? void 0 : answerFeedback.wrong);
        }
        else {
            currentElement === null || currentElement === void 0 ? void 0 : currentElement.insertAdjacentHTML('afterend', QUIZ[STEP].feedback.wrong);
        }
        // sauvegarde des réponses de l'utilisateur
        if (!reviewQuiz) {
            saveAnswerSelected(answers);
        }
        if (STEP === QUIZ.length - 1) {
            createBtnSeeMyResults();
        }
        else {
            createBtnNext();
        }
    }
}
/**
 * test les réponses de l'utilisateur en cas de plusieurs bonnes reponses possible
 * @param e MouseEvent
 */
function testAnswer(e, reviewQuiz = false) {
    var _a, _b, _c, _d, _e, _f;
    e.preventDefault();
    e.stopPropagation();
    if (!reviewQuiz && !!QUIZ[STEP].validated) {
        return;
    }
    if (!reviewQuiz) {
        QUIZ[STEP].validated = true;
    }
    let success = false;
    const btnTestAnswersElements = Array.prototype.slice.call(document.querySelectorAll('.btn-test-answer'));
    const currentElement = !reviewQuiz
        ? e.currentTarget
        : btnTestAnswersElements[0];
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    if (reviewQuiz) {
        const answersRegistered = ANSWER_REGISTERED[STEP];
        answers.forEach((answerElement, index) => {
            var _a;
            if (answerElement.value === ((_a = answersRegistered === null || answersRegistered === void 0 ? void 0 : answersRegistered.answersSelected[index]) === null || _a === void 0 ? void 0 : _a.value)) {
                answerElement.setAttribute('selected', 'true');
            }
        });
    }
    const totalCorrectAnswersAvailable = answers.filter((answer) => answer.getAttribute('correct') === 'true').length;
    const correctAnswers = answers.filter((answer) => answer.getAttribute('correct') === 'true' && answer.getAttribute('selected') === 'true').length;
    const wrongAnswers = answers.filter((answer) => answer.getAttribute('correct') === 'false' && answer.getAttribute('selected') === 'true').length;
    const missingAnswers = answers.filter((answer) => answer.getAttribute('correct') === 'true' &&
        (!answer.getAttribute('selected') || answer.getAttribute('selected') === 'false')).length;
    // si l'utilisateur a trouvé toutes les bonnes réponses
    if (!wrongAnswers && !missingAnswers && correctAnswers === totalCorrectAnswersAvailable) {
        success = true;
        changeBodyBackground(['success']);
        currentElement.insertAdjacentHTML('beforebegin', QUIZ[STEP].feedback.correct);
    }
    // si l'utilisateur a trouvé au moins une bonne réponse
    if ((!!wrongAnswers || !!missingAnswers) && correctAnswers >= 1) {
        success = true;
        changeBodyBackground(['success']);
        currentElement.insertAdjacentHTML('beforebegin', ((_b = (_a = QUIZ[STEP]) === null || _a === void 0 ? void 0 : _a.feedback) === null || _b === void 0 ? void 0 : _b.partial) !== '' ? (_d = (_c = QUIZ[STEP]) === null || _c === void 0 ? void 0 : _c.feedback) === null || _d === void 0 ? void 0 : _d.partial : (_f = (_e = QUIZ[STEP]) === null || _e === void 0 ? void 0 : _e.feedback) === null || _f === void 0 ? void 0 : _f.wrong);
    }
    // si l'utilisateur a trouvé aucunes bonnes réponses
    if ((!!wrongAnswers || !!missingAnswers) && !correctAnswers) {
        currentElement.insertAdjacentHTML('beforebegin', QUIZ[STEP].feedback.wrong);
    }
    // met les classes css pour le feedback de la correction
    answers.forEach((answer) => {
        if (answer.getAttribute('selected') === 'true') {
            answer.classList.remove('selected');
            answer.setAttribute('validated', 'true');
        }
        if (answer.getAttribute('correct') === 'true') {
            answer.classList.add('was-correct');
        }
        if (answer.getAttribute('correct') !== 'true' && answer.getAttribute('selected') === 'true') {
            answer.classList.add('was-wrong');
        }
    });
    if (!reviewQuiz) {
        saveAnswerSelected(answers, success);
    }
    // suppression du bouton tester ma réponse pour le remplacer par question suivante ou voir mes résultats
    btnTestAnswersElements.forEach((btn) => btn.remove());
    if (STEP === QUIZ.length - 1) {
        createBtnSeeMyResults();
    }
    else {
        createBtnNext();
    }
}
/**
 * permet d'ajouter une classe css pour l'etat selectionné ou deselectionné en cas de plusieurs réponses possibles
 * @param event
 */
function selectionForMultipleAnswers(event, isSubquestion) {
    event.preventDefault();
    event.stopPropagation();
    const currentElement = event.currentTarget;
    if (isSubquestion) {
        const allBtnElements = Array.prototype.slice.call(document.querySelectorAll('.answer'));
        allBtnElements.forEach((answerElement) => {
            answerElement.setAttribute('selected', 'false');
            answerElement.classList.remove('selected');
        });
        currentElement.setAttribute('selected', 'true');
        currentElement.classList.add('selected');
        createBtnSeeQuestion();
    }
    else {
        if (!!QUIZ[STEP].validated) {
            return;
        }
        if (currentElement.getAttribute('selected') === 'true') {
            currentElement.setAttribute('selected', 'false');
            currentElement.classList.remove('selected');
        }
        else {
            currentElement.setAttribute('selected', 'true');
            currentElement.classList.add('selected');
        }
    }
}
/**
 * Enregistrement des réponses de l'utilisateur
 * @param answers
 */
function saveAnswerSelected(answers, success = false, isSubquestion = false) {
    if (ANSWER_REGISTERED[STEP]) {
        if (isSubquestion) {
            ANSWER_REGISTERED[STEP].subquestionAnswersSelected = { value: answers[0].getAttribute('value') };
        }
        else {
            ANSWER_REGISTERED[STEP].correct =
                success ||
                    !answers.some((answerElement) => answerElement.getAttribute('validated') === 'true' &&
                        answerElement.getAttribute('correct') !== 'true');
            ANSWER_REGISTERED[STEP].answersSelected = answers
                .filter((answerElement) => answerElement.getAttribute('validated') === 'true')
                .map((answerElement) => ({
                value: answerElement.getAttribute('value'),
                correct: answerElement.getAttribute('correct') === 'true',
            }));
        }
    }
    else {
        if (isSubquestion) {
            ANSWER_REGISTERED.push({
                correct: false,
                answersSelected: [],
                subquestionAnswersSelected: isSubquestion ? { value: answers[0].getAttribute('value') } : undefined,
            });
        }
        else {
            ANSWER_REGISTERED.push({
                correct: success ||
                    !answers.some((answerElement) => answerElement.getAttribute('validated') === 'true' &&
                        answerElement.getAttribute('correct') !== 'true'),
                answersSelected: answers
                    .filter((answerElement) => answerElement.getAttribute('validated') === 'true')
                    .map((answerElement) => ({
                    value: answerElement.getAttribute('value'),
                    correct: answerElement.getAttribute('correct') === 'true',
                })),
                subquestionAnswersSelected: isSubquestion ? { value: answers[0].getAttribute('value') } : undefined,
            });
        }
    }
}
function getWrapperBtnElement(justifyContentEnd = false) {
    const quizContainer = document.querySelector('#quiz-container');
    const wrapperBtnAlreadyCreated = document.querySelector('#wrapper-btn');
    if (!wrapperBtnAlreadyCreated) {
        const wrapperBtnConf = {
            attributes: {
                id: 'wrapper-btn',
            },
            classList: justifyContentEnd ? ['row', 'justify-content-end'] : ['row', 'justify-content-between'],
            innerHTML: '',
            tagName: 'div',
        };
        const wrapperBtnElement = new Dynamically_create_element(wrapperBtnConf).create();
        quizContainer === null || quizContainer === void 0 ? void 0 : quizContainer.appendChild(wrapperBtnElement);
        return wrapperBtnElement;
    }
    else {
        if (justifyContentEnd) {
            wrapperBtnAlreadyCreated.classList.remove('justify-content-between');
            wrapperBtnAlreadyCreated.classList.add('justify-content-end');
        }
        else {
            wrapperBtnAlreadyCreated.classList.remove('justify-content-end');
            wrapperBtnAlreadyCreated.classList.add('justify-content-between');
        }
    }
    return wrapperBtnAlreadyCreated;
}
/**
 * création du bouton suivant
 */
function createBtnNext() {
    const btnNextAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-next'));
    if (!!btnNextAlreadyCreated.length) {
        btnNextAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnNextConf = {
        attributes: {
            name: 'btn-next',
            type: 'submit',
            value: 'Question Suivante',
        },
        classList: ['btn-next', 'col-12', 'col-md-6', 'col-lg-5', 'btn-next', 'order-2'],
        innerHTML: '<label>Question suivante</label>',
        onclick: (e) => nextQuestion(e),
        tagName: 'button',
    };
    const btnNext = new Dynamically_create_element(btnNextConf).create();
    getWrapperBtnElement(STEP === 0).appendChild(btnNext);
}
function createBtnPrevious() {
    const btnPreviousAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-next'));
    if (!!btnPreviousAlreadyCreated.length) {
        btnPreviousAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnPreviousConf = {
        attributes: {
            name: 'btn-previous',
            type: 'submit',
            value: 'Question précédente',
        },
        classList: ['col-12', 'btn-previous', 'col-md-6', 'col-lg-5', 'order-1'],
        innerHTML: '<label>Question précédente</label>',
        onclick: (e) => previousQuestion(e),
        tagName: 'button',
    };
    const btnPrevious = new Dynamically_create_element(btnPreviousConf).create();
    getWrapperBtnElement().appendChild(btnPrevious);
}
/**
 * création du bouton "voir mes resultats"
 */
function createBtnSeeMyResults() {
    const btnSeeMyResultsAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-see-my-results'));
    if (!!btnSeeMyResultsAlreadyCreated.length) {
        btnSeeMyResultsAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnSeeMyResultsConf = {
        attributes: {
            name: 'btn-see-my-results',
            type: 'submit',
            value: 'Voir mes résultats',
        },
        classList: ['col-12', 'btn-see-my-results', 'col-md-6', 'col-lg-5', 'order-2'],
        innerHTML: '<label>Voir mes résultats</label>',
        onclick: (e) => seeMyResults(e),
        tagName: 'button',
    };
    const btnSeeMyResults = new Dynamically_create_element(btnSeeMyResultsConf).create();
    getWrapperBtnElement().appendChild(btnSeeMyResults);
}
/**
 * création du bouton "Tester ma réponse"
 */
function createBtnTestAnswer() {
    const btnTestAnswerAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-test-answer'));
    if (!!btnTestAnswerAlreadyCreated.length) {
        btnTestAnswerAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnTestAnswerConf = {
        attributes: {
            name: 'btn-test-answer',
            type: 'submit',
            value: 'Voir la réponse',
        },
        classList: ['col-12', 'btn-test-answer', 'col-md-6', 'col-lg-5', 'order-2'],
        innerHTML: '<label>Valider ma réponse</label>',
        onclick: (e) => testAnswer(e),
        tagName: 'button',
    };
    const btnTestAnswer = new Dynamically_create_element(btnTestAnswerConf).create();
    getWrapperBtnElement().appendChild(btnTestAnswer);
}
/**
 * creation du bouton voir la question
 */
function createBtnSeeQuestion() {
    const btnSeeQuestionAlreadyCreated = Array.prototype.slice.call(document.querySelectorAll('.btn-see-question'));
    if (!!btnSeeQuestionAlreadyCreated.length) {
        btnSeeQuestionAlreadyCreated.forEach((btn) => btn.remove());
    }
    const btnTestAnswerConf = {
        attributes: {
            name: 'btn-see-question',
            type: 'submit',
            value: 'Voir la question',
        },
        classList: ['col-12', 'btn-see-question', 'col-md-6', 'col-lg-5', 'order-2'],
        innerHTML: '<label>Voir la question</label>',
        onclick: (e) => seeQuestion(e),
        tagName: 'button',
    };
    const btnSeeQuestion = new Dynamically_create_element(btnTestAnswerConf).create();
    getWrapperBtnElement().appendChild(btnSeeQuestion);
}
function seeQuestion(event) {
    event.preventDefault();
    event.stopPropagation();
    const answers = Array.prototype.slice.call(document.querySelectorAll('.answer'));
    const answerSelected = answers.filter((answer) => answer.getAttribute('selected') === 'true');
    saveAnswerSelected(answerSelected, false, true);
    removeClassesFromElement('body', ['success']);
    initializeQuiz();
    createAndRenderQuiz();
}
/**
 * permets de passer à l'affichage d'un modèle qui affiche le nombre de bonnes réponse
 * @param e
 */
function seeMyResults(e) {
    e.preventDefault();
    e.stopPropagation();
    changeBodyBackground(['recap'], ['success', `fond-${STEP + 1}`]);
    // Tres important, les classes à supprimer col-* dans la div #wrapper-column doivent être les mêmes que celle dans le fichier index.html
    removeClassesFromElement('#wrapper-column', ['col-lg-6']);
    const sectionQuiz = document.getElementById('section-quiz');
    const sectionSeeMyResults = document.getElementById('section-see-my-results');
    if (sectionQuiz) {
        sectionQuiz.innerHTML = '';
    }
    const template = document.querySelector('#my-results');
    const clone = template.content.cloneNode(true);
    if (clone) {
        const elementToInjectResult = clone.querySelector('#results');
        if (elementToInjectResult) {
            elementToInjectResult.innerHTML = calculateCorrectAnswerRegistered() || '0';
            elementToInjectResult.insertAdjacentHTML('afterend', +calculateCorrectAnswerRegistered() > 1
                ? '<span> bonnes réponses</span>'
                : '<span> bonne réponse</span>');
        }
    }
    sectionSeeMyResults === null || sectionSeeMyResults === void 0 ? void 0 : sectionSeeMyResults.appendChild(clone);
}
/**
 * calcul le nombre de bonnes réponses du quiz (une mauvaise réponse + une bonne réponse = mauvaise réponse)
 */
function calculateCorrectAnswerRegistered() {
    return ANSWER_REGISTERED.filter((answer) => !!answer.correct).length.toString();
}
/**
 * récupère les réponses du quiz depuis les data et crée-les elements HTML
 * @param quiz
 */
function setAnswers(quiz, isSubquestion) {
    return (quiz.answers || []).map((answer) => {
        const data = {
            classList: answer.classList,
            innerHTML: answer.innerHTML,
            tagName: answer.tagName,
            onclick: (e) => !!quiz.multiple ||
                (quiz.answers || []).filter((answer) => { var _a; return ((_a = answer === null || answer === void 0 ? void 0 : answer.attributes) === null || _a === void 0 ? void 0 : _a.correct) === 'true'; }).length > 1
                ? selectionForMultipleAnswers(e, !!isSubquestion)
                : correction(e),
            attributes: answer.attributes,
        };
        return new Dynamically_create_element(data).create();
    });
}
/**
 * utilitaire permettant de récupérer les params de l'url
 * @param url
 */
function getAllUrlParams(url) {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    // we'll store the parameters here
    const obj = {};
    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];
        // split our query string into its component parts
        const arr = queryString.split('&');
        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            const a = arr[i].split('=');
            // set parameter name and value (use 'true' if empty)
            let paramName = a[0];
            let paramValue = typeof a[1] === 'undefined' ? true : a[1];
            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string')
                paramValue = paramValue.toLowerCase();
            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                const key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key])
                    obj[key] = [];
                // otherwise add the value to the end of the array
                obj[key].push(paramValue);
            }
            else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                }
                else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist, and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                }
                else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }
    return obj;
}
function createEvent(data) {
    // To assign event
    const event = new CustomEvent('start', {
        detail: data,
    });
    // To trigger the Event
    document.dispatchEvent(event);
}
